// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const ActivityStatus = {
  "PLANNED": "PLANNED",
  "IN_PROGRESS": "IN_PROGRESS",
  "COMPLETED": "COMPLETED"
};

const ActivityDimension = {
  "HABITUAL": "HABITUAL",
  "EMOTIONAL": "EMOTIONAL",
  "SPIRITUAL": "SPIRITUAL",
  "RATIONAL": "RATIONAL",
  "MINDFUL": "MINDFUL"
};

const ActivityBehaviour = {
  "THINKING": "THINKING",
  "DOING": "DOING",
  "BEING": "BEING",
  "WORKING": "WORKING",
  "PLAYING": "PLAYING",
  "RELAXING": "RELAXING",
  "LEARNING": "LEARNING",
  "RESEARCHING": "RESEARCHING",
  "PLANNING": "PLANNING",
  "DESIGNING": "DESIGNING",
  "AUTHORING": "AUTHORING",
  "PRACTICING": "PRACTICING",
  "IMPLEMENTING": "IMPLEMENTING",
  "TEACHING": "TEACHING",
  "WORRYING": "WORRYING",
  "RUMINATING": "RUMINATING",
  "MISBEHAVING": "MISBEHAVING"
};

const { User, Topic, Activity } = initSchema(schema);

export {
  User,
  Topic,
  Activity,
  ActivityStatus,
  ActivityDimension,
  ActivityBehaviour
};