import logo from './logo.svg';
import './App.css';
import TopicCreateForm from './ui-components/TopicCreateForm';
import { Flex, withAuthenticator } from '@aws-amplify/ui-react';

import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
Amplify.configure(awsExports);

function App() {
  return (
    <Flex>
      <TopicCreateForm />
    </Flex>
  );
}

export default withAuthenticator(App);
